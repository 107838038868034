import Image1 from '../../assets/logo-no-background.png'

export const Data = [
  {
    id: 1,
    image: Image1,
    title: '',
    description: '',
    href: ''
  },
  {
    id: 2,
    image: Image1,
    title: '',
    description: '',
    href: ''
  },
  {
    id: 3,
    image: Image1,
    title: '',
    description: '',
    href: ''
  }
]
